<template>
    <div class="col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1 col-sm-12 col-xs-12">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2 mb-2" v-if="showReviewDetails">
            <ReviewDetails/>
        </div>
        <!--KYC screen-->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3" data-v-if="needKYC">
            <div class="row" style="margin-top:-30px">
                <div class="text-center wrapper" v-if="iframeSrc">
                    <iframe
                            id="triplea-payment-form"
                            :src="iframeSrc"
                            width="75%"
                            height="590px"
                            class="w-100"
                    >
                    </iframe>
                    <div v-if="paymentSuccessful">
                        <div class="row">
                            <button class="btn btn-primary text-center w-30"
                                    @click="continueToDashboard()">
                                Continue
                            </button>
                        </div>
                    </div>
                    <div v-else-if="paymentExpired">
                        <button @click="makePaymentAgain()" class="btn btn-primary text-center w-30">
                            Pay Again
                        </button>
                    </div>
<!--                  <div v-else>-->
<!--                    <h3>Waiting for payment...</h3>-->
<!--                  </div>-->
                </div>
            </div>
            <!--                    <div class="row">-->
            <!--                        <div class="kyc-container">-->
            <!--                            <h3 class="text-center mt-2 mb-3">Identity Verification</h3>-->
            <!--                            <br>-->
            <!--                            <br>-->
            <!--                            <p class="text-center mt-3">We only require for your identity document when :</p>-->
            <!--                            <ul class="text-center">-->
            <!--                                <li>-->
            <!--                                    - the transfer amount is above SGD 1,500.00 equivalent-->
            <!--                                </li>-->
            <!--                                <li>-->
            <!--                                    - aggregated monthly transfer amount is above SGD 15,000.00 equivalent.-->
            <!--                                </li>-->
            <!--                            </ul>-->
            <!--                            <p>Please proceed to the next step to make payment</p>-->
            <!--                            <div class="row">-->
            <!--                                <button class="form-btn text-center mt-3" @click="submitKYCDetails()">Continue</button>-->
            <!--                            </div>-->


            <!--                        </div>-->
            <!--                    </div>-->
        </div>

        <!--Payment screen-->
        <!--        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">-->
        <!--            <div class="row" style="margin-top:-30px">-->
        <!--                <div class="row text-center">-->
        <!--                    <div class="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-12">-->
        <!--                        <div style="display: flex;flex-direction: column;padding:10px;margin-bottom:10px">-->
        <!--                            <span>Once the crypto payment is successful, TransCrypt will transfer the fund to recipient's bank within <strong>one business day</strong>. </span>-->
        <!--                            <span>Notification is sent to recipient & your email.</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="text-center wrapper" v-if="iframeSrc">-->
        <!--                    <iframe-->
        <!--                            id="triplea-payment-form"-->
        <!--                            :src="iframeSrc"-->
        <!--                            width="75%"-->
        <!--                            height="590px"-->
        <!--                            class="w-100"-->
        <!--                    >-->
        <!--                    </iframe>-->
        <!--                    <div class="row text-center">-->
        <!--                        <div class="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-12">-->
        <!--                            <div style="display: flex;flex-direction: column;padding:10px;margin-bottom:10px">-->
        <!--                                <span>If you have any questions, please contact us at <a class="text-success" href="mailto:support@transcryptglobal.com?subject=Crypto%20Transfer%20Enquiry">support@transcryptglobal.com</a></span>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div v-if="paymentSuccessful">-->
        <!--                        <div class="row">-->
        <!--                            <button class="btn btn-primary text-center w-30"-->
        <!--                                    @click="continueToDashboard()">-->
        <!--                                Continue-->
        <!--                            </button>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div v-else-if="paymentExpired">-->
        <!--                        <button @click="makePaymentAgain()" class="btn btn-primary text-center w-30">-->
        <!--                            Pay Again-->
        <!--                        </button>-->
        <!--                    </div>-->

        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

    </div>
</template>
<script>
    import {mapActions, mapGetters} from 'vuex';
    import ReviewDetails from './ReviewDetailsComponent'

    export default {
        name: 'make-payment',
        components: {
            ReviewDetails
        },
        computed: {
            ...mapGetters(["paymentDetailsGetter", "paymentRequestGetter"]),

            recipientFullName() {
                return this.paymentDetailsGetter && this.paymentDetailsGetter.recipientInfo && this.paymentDetailsGetter.recipientInfo.recipientFullName;
            },

            recipientEmail() {
                return this.paymentDetailsGetter && this.paymentDetailsGetter.recipientInfo && this.paymentDetailsGetter.recipientInfo.recipientEmail;
            },
            receivingAmount() {
                let amount = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.receiveAmount;
                let currency = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.receiveCurrency;
                return amount + " " + currency;
            }


        },
        data() {
            return {
                // infoForRecipient: false,

                mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

                iframeSrc: "",
                iframeHeight: "",
                apiId: "HA16076518581Vkb_t",
                messages: [],
                paymentSuccessful: false,
                paymentExpired: false,

                paymentInfo: null,

                //KYC
                needKYC: false,
                kycStatus: "",

                debugConsole: false,

                showReviewDetails : false,

            }
        },
        watch: {
            $route(to, from) {
                if (to.path === '/transfer/make-payment') {
                    console.log("Watch route in transfer make payment");
                    let target = "payment-info";
                    this.$store.commit('UPDATE_STEPPER', target);
                }
            }
        },
        metaInfo: {
            title: 'TransCrypt - Make Payment | Send Money Faster with Cryptocurrency',
        },
        mounted() {
            if (this.debugConsole) console.debug("Make Payment Mounted");
            let url = new URL(window.location)

            //for stepper
            let target = "payment-info";
            this.$store.commit('UPDATE_STEPPER', target);

            this.messageListener();
            if (this.debugConsole) console.log("Payment Request Getter", this.paymentDetailsGetter);

            //check if url has tx reference as query string
            let txReference = url.searchParams.get('tx_ref_num');

            if (this.debugConsole) console.log("Tx Reference in URL", txReference);
            if (txReference) {
                if (this.debugConsole) console.log("Tx reference in url exist");
                //use the txReference to call API to get the hosted url
                let payload = {};
                payload.txReference = txReference;

                this.getPaymentRequest(payload).then(res => {

                    if (this.debugConsole) console.log("Payment Request result in transfer make payment", res.data);

                    if (res.data && res.data.hosted_url) {
                        this.iframeSrc = res.data.hosted_url;

                        this.showReviewDetails = true;
                    }

                }).catch(err => {
                    if (this.debugConsole) console.log("Error in payment request", err.response);
                })


            } else {
                if (this.debugConsole) console.log("Tx reference in url not exist");
                this.iframeSrc = this.paymentDetailsGetter && this.paymentDetailsGetter.paymentRequestInfo && this.paymentDetailsGetter.paymentRequestInfo.hostedURL;
                this.showReviewDetails = true;
                if (this.debugConsole) console.log('Iframe Src', this.iframeSrc);
            }


            //check if need kyc or not
            //if need needKYC = true, show KYC screen, go to payment screen form.
            //probably need to take the tx Reference from the URL and check the necessary details.
            // let kycUrl = this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.kycUrl;
            // if(kycUrl)
            // {
            //     this.needKYC = false;
            // }
            // else
            // {
            //     this.needKYC = true;
            // }


        },

        methods: {
            ...mapActions(["getPaymentRequest"]),

            makePaymentAgain() {
                //check if url has tx reference as query string
                let url = new URL(window.location)
                let txReference = url.searchParams.get('tx_ref_num');

                if (txReference) {
                    if (this.debugConsole) console.log("Tx reference in url exist");
                    //use the txReference to call API to get the hosted url
                    let payload = {};
                    payload.txReference = txReference;

                    this.getPaymentRequest(payload).then(res => {

                        if (this.debugConsole) console.log("Payment Request result in transfer make payment when make payment again", res.data);

                        if (res.data && res.data.hosted_url) {
                            this.iframeSrc = res.data.hosted_url;
                        }

                    }).catch(err => {
                        if (this.debugConsole) console.log("Error in payment request when make payment again", err.response);
                    })


                } else {
                    if (this.debugConsole) console.log("Tx reference in url not exist when making payment again");
                    let payload = {};
                    payload.txReference = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo.txReference;

                    this.getPaymentRequest(payload).then(res => {

                        if (this.debugConsole) console.log("Payment Request result in making payment again (no tx ref in url)", res.data);

                        if (res.data && res.data.hosted_url) {
                            this.iframeSrc = res.data.hosted_url;
                        }

                    }).catch(err => {
                        if (this.debugConsole) console.log("Error in making payment request again (no tx ref in url)", err.response);
                    })
                }

            },
            continueToDashboard() {

                // //just temporary.Will display the transactions based on backend API.
                // console.log("Transaction to send to dashboard", this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo)
                // let transaction = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo;
                // this.$store.commit('UPDATE_TRANSACTION', transaction);


                this.$router.push('/dashboard');


            },
            // goToDashboard() {
            //     if (this.paymentInfo) {
            //         //Temporarily do this to store the data in Vuex store
            //         let transaction = {}
            //         transaction.sendAmount = this.paymentInfo.order_amount;
            //         transaction.sendCurrency = this.paymentInfo.order_currency;
            //         transaction.cryptoCurrency = this.paymentInfo.crypto_currency;
            //         transaction.receiveAmount = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.receiveAmount;
            //         transaction.receiveCurrency = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.receiveCurrency;
            //         transaction.cryptoAmount = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.cryptoAmount;
            //         transaction.recipientName = this.paymentSuccessful && this.paymentDetailsGetter.recipientInfo && this.paymentDetailsGetter.recipientInfo.recipientFullName;
            //
            //         console.log("Transaction", transaction);
            //
            //         this.$store.commit('UPDATE_TRANSACTION', transaction);
            //
            //         this.$router.push('/dashboard');
            //     }
            //
            // },
            // continueForRecipientBankDetailsUnknown() {
            //     this.infoForRecipient = true;
            // },

            messageListener() {
                window.addEventListener('message', e => {
                    if (e.data && typeof e.data === 'string' && e.data.includes("triplea.")) {

                        this.messages.push(e.data);
                        if (this.debugConsole) console.log("Messages", this.messages);

                        // console.log("Message array before", this.messages);
                        //
                        if (e && e.data && e.data.includes && e.data.includes("triplea.frameResized|")) {
                            if (this.debugConsole) console.log("Message array", this.messages);
                            let index = this.messages && this.messages.findIndex(element => element.includes('triplea.frameResized'));
                            if (this.debugConsole) console.log("Index", index);
                            this.messages[index] = e.data;
                        }
                        if (this.debugConsole) console.log("Message array after", this.messages);


                        // //Determine the messages shown whether payment has been made.
                        if (this.messages.indexOf('triplea.paymentSuccess') >= 0 || this.messages.indexOf('triplea.badTransaction') >= 0) {
                            this.paymentSuccessful = true;
                        } else {
                            this.paymentSuccessful = false;
                        }

                        if (this.messages.indexOf('triplea.formExpired') >= 0) {
                            this.paymentExpired = true;
                        } else {
                            this.paymentExpired = false;
                        }


                        // //for iframeHeight
                        // let filteredListFrameResized = this.messages && [...this.messages.filter(element => element.includes('triplea.frameResized|'))]
                        // let lastfilteredListFrameResized = filteredListFrameResized[filteredListFrameResized.length-1];
                        // console.log("FilteredList", filteredListFrameResized);
                        // console.log("Last Filtered List Frame Resized", lastfilteredListFrameResized);
                        // this.iframeHeight = filteredListFrameResized.length > 0 ? `${lastfilteredListFrameResized.split('|')[1]}px` : "";
                    }
                }, false)
            },


        }
    }
</script>
<style scoped>


    #triplea-payment-form {
        -moz-transform: scale(0.95);
        -webkit-transform: scale(0.95);
        -o-transform: scale(0.95);
        -ms-transform: scale(0.95);
        border: 0;
    }

    .wrapper {
        margin: 0.8px;
    }


</style>